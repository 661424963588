.work_container {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.work_header {
  text-align: center;
  padding-right: 8rem;
  padding-bottom: 2rem;
}

.work_card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.work_img {
  width: 250px;
  height: 150px;
  border-radius: 1rem;
  object-fit: cover;
  border-radius: 1rem;
  align-self: center;
}

.work_title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.work_description {
  font-size: var(--small-font-size);
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
}

.work_tags {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work_button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.work_button:hover {
  color: #a52a2a;
}

.work_button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work_button:hover .work_button-icon {
  transform: translateX(0.25rem);
}

/* Breakpoints for responsiveness */
/* Large devices */
@media screen and (max-width: 992px) {
  .work_container {
    gap: 1.25rem;
  }

  .work_card {
    padding: 1rem;
  }

  .work_img {
    margin-bottom: 0.75rem;
  }

  .work_title {
    margin-bottom: 0.25rem;
  }
}

/* Medium devices */
@media screen and (max-width: 768px) {
  .work_header {
    padding-right: 0;
  }
}

@media screen and (max-width: 576px) {
}

/* Small devices */
@media screen and (max-width: 350px) {
}
