.home {
  height: 100vh;
}

.home_content {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 30px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home_title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
  color: var(--title-color);
}

.home_subtitle {
  position: relative;
  font-size: var(--h2-font-size);
  padding-left: 7rem;
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-3);
}

.home_subtitle::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 1px;
  background-color: var(--line-color);
  left: 0;
  top: 1.2rem;
}

.home_description {
  max-width: 400px;
  margin-bottom: var(--mb-0-5);
}

.home_welcome {
  margin-top: 2.5rem;
  font-weight: var(--font-medium);
}

.down-arrow {
  position: absolute;
  bottom: 3rem;
  left: 48.3%;
}

.steaming-coffee {
  order: 1;
  position: relative;
  background: url("../../assets/skyline.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -2rem 0rem;
}

.cup {
  position: relative;
  width: 110px;
  height: 100px;
  background: linear-gradient(to right, #f9f9f9, #d9d9d9);
  border: 1px solid var(--line-color);
  border-top: none;
  top: 155px;
  left: 230px;
  border-radius: 0 0 45% 45%;
}

.top {
  position: absolute;
  top: -20px;
  left: -0.1px;
  width: 101%;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(to right, #f9f9f9, #d9d9d9);
  border: 1px solid var(--line-color);
}

.circle {
  position: absolute;
  top: 2px;
  left: 4px;
  width: calc(100% - 7px);
  height: 33px;
  background: linear-gradient(to left, #f9f9f9, #d9d9d9);
  border: 1px solid var(--line-color);
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
}

.tea {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #3a281c;
}

.handle1 {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid var(--line-color);
  border-radius: 50%;
  left: 102px;
  top: 20px;
  z-index: -1;
}

.handle2 {
  content: "";
  position: absolute;
  width: 45px;
  height: 40px;
  border: 1px solid var(--line-color);
  border-radius: 50%;
  left: 85px;
  top: 10px;
  z-index: -1;
}

.plate {
  position: absolute;
  bottom: -26px;
  left: 48%;
  transform: translateX(-50%);
  width: 210px;
  height: 80px;
  background: linear-gradient(to right, #f9f9f9, #e7e7e7);
  border-radius: 50%;
  border: 1px solid var(--line-color);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.plate::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  background: linear-gradient(to right, #f9f9f9, #e7e7e7);
}

.plate::after {
  content: "";
  position: absolute;
  top: 30px;
  left: 55px;
  right: 10px;
  bottom: 10px;
  background: radial-gradient(
    rgba(0, 0, 0, 0.5),
    25%,
    transparent,
    transparent
  );
  border-radius: 50%;
}

.test {
  position: relative;
  width: 200px;
  height: 60px;
  overflow: hidden;
  opacity: 0.5;
  top: -30px;
  left: 235px;
}

.steam {
  position: absolute;
  top: 100px;
  animation: steam 3s linear infinite;
}

.steam-2 {
  position: absolute;
  top: 100px;
  left: 30px;
  animation: steam 3s linear infinite;
}

.steam-3 {
  position: absolute;
  top: 100px;
  left: 60px;
  animation: steam 3s linear infinite;
}

.one {
  position: absolute;
}

.two {
  position: absolute;
}

.steam:before,
.one:before,
.two:before,
.steam-2:before,
.steam-3:before {
  content: "";
  position: absolute;
  border-left: 7px solid var(--line-color-light);
  border-top: 7px solid var(--line-color-light);
  width: 30px;
  height: 30px;
  border-top-left-radius: 100%;
  transform: rotate(-40deg);
}

.one:before {
  top: -93px;
  left: 0;
}

.two:before {
  top: -185px;
  left: 1.2px;
}

.steam:after,
.one:after,
.two:after,
.steam-2:after,
.steam-3:after {
  content: "";
  position: absolute;
  border-right: 7px solid var(--line-color-light);
  border-top: 7px solid var(--line-color-light);
  width: 30px;
  height: 30px;
  border-top-right-radius: 100%;
  transform: rotate(40deg);
}

.steam:after {
  top: -46.5px;
  left: 4px;
}

.one:after {
  top: -139.5px;
  left: 4px;
}

.two:after {
  top: -231px;
  left: 4.7px;
}

.steam-2:after {
  top: -46.5px;
  left: 4.5px;
}

.steam-3:after {
  top: -46.5px;
  left: 4.5px;
}

@keyframes steam {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(7px);
  }
}

/* Breakpoints for responsiveness */
/* Large devices */
@media screen and (max-width: 992px) {
  .home_content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home_subtitle {
    padding-left: 3.75rem;
    margin-bottom: 4rem;
  }

  .home_subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home_description {
    max-width: initial;
  }
}

/* Medium devices */
@media screen and (max-width: 768px) {
  .home {
    height: fit-content;
  }

  .home_content {
    padding-top: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home_data {
    grid-column: 1/3;
  }

  .down-arrow {
    visibility: hidden;
  }

  .cup {
    top: 150px;
    left: 0;
  }

  .test {
    position: relative;
    width: 100px;
    height: 50px;
    overflow: hidden;
    opacity: 0.5;
    top: -30px;
    left: 15px;
  }

  .steam {
    position: absolute;
    top: 50px;
    left: 0px;
    animation: steam 3s linear infinite;
  }

  .steam-2 {
    position: absolute;
    top: 50px;
    left: 20px;
    animation: steam 3s linear infinite;
  }

  .steam-3 {
    position: absolute;
    top: 50px;
    left: 40px;
    animation: steam 3s linear infinite;
  }
}
