#skills {
  padding-top: 1rem;
}

.skills_header {
  text-align: start;
  padding-left: 4rem;
  padding-bottom: 2rem;
}

.skills_container {
  display: flex;
  justify-content: space-evenly;
}

.skills_group {
  grid-template-columns: repeat(2, 70px);
  gap: 3rem;
  column-gap: 3rem;
  justify-content: center;
}

.skills_item {
  justify-items: center;
  text-align: center;
  font-size: var(--small-font-size);
}

.skills_item img {
  margin-top: 1rem;
  width: 60px;
  height: 60px;
}

.skills_content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 1.25rem;
}

.skills_title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills_box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills_group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills_data {
  display: flex;
  column-gap: 0.5rem;
}

.skills .bx-badge-check {
  font-size: 1rem;
  color: var(--title-color);
}

.skills_name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills_level {
  font-size: var(--tiny-font-size);
}

/* Breakpoints for responsiveness */
/* Large devices */
@media screen and (max-width: 992px) {
  .skills_container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .skills_container {
    flex-direction: column;
  }

  .skills_header {
    text-align: center;
    padding-left: 0;
  }
}

/* Medium devices */
@media screen and (max-width: 576px) {
  .skills_container {
    grid-template-columns: 1fr;
  }

  .skills_content {
    padding: 1.5rem;
  }
}

/* Small devices */
@media screen and (max-width: 350px) {
  .skills_box {
    column-gap: 1.25rem;
  }

  .skills_name {
    font-size: var(--small-font-size);
  }
}
