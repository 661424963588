.scrollup {
  position: fixed;
  right: 2.5rem;
  bottom: -20%;
  background-color: var(--title-color-dark);
  opacity: 0.8;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup_icon {
  font-size: 1.5rem;
  color: var(--container-color);
}

.scrollup:hover {
  background-color: var(--text-color);
}

.show-scroll {
  bottom: 3rem;
}

/* Breakpoints for responsiveness */
/* Large devices */
@media screen and (max-width: 992px) {
  .scrollup {
    right: 1.5rem;
    padding: 0.25rem 0.4rem;
  }

  .scrollup_icon {
    font-size: 1.25rem;
  }
}

/* Medium devices */
@media screen and (max-width: 768px) {
  .show-scroll {
    bottom: 4rem;
  }
}

/* Small devices */
@media screen and (max-width: 350px) {
  .scrollup {
    right: 1rem;
  }
}
