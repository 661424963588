.about_container {
  grid-template-columns: 1.1fr 1fr;
  align-items: center;
  column-gap: 1rem;
}

.about_header {
  text-align: end;
  padding-right: 11rem;
  padding-bottom: 2rem;
}

.about_info {
  grid-template-columns: 255px;
  margin-bottom: var(--mb-2);
  margin-right: 1rem;
  justify-content: center;
  padding-left: 2rem;
  border-right: 1px solid var(--line-color);
}

.about_box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}

.about_icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}

.about_title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.about_subtitle {
  font-size: var(--small-font-size);
}

.about_description {
  margin-bottom: var(--mb-2-5);
  padding-left: 3rem;
  text-align: justify;
}

.about_description div {
  margin: 1rem;
}

.about_skills_intro {
  margin: 1rem 0 1rem 0;
}

.about_skills {
  display: flex;
  justify-content: space-between;
  margin-left: 15%;
  margin-right: 15%;
}

.about_skills ul {
  list-style: square;
}

.about_description p {
  display: inline;
}

.highlight {
  font-weight: var(--font-semi-bold);
}

.about_date {
  margin-top: 0.5rem;
  font-size: var(--smaller-font-size);
}

.download_container {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download_button {
  display: flex;
}

/* Breakpoints for responsiveness */
/* Large devices */
@media screen and (max-width: 992px) {
  .about_box {
    padding: 0.75rem;
  }

  .about_info {
    grid-template-columns: 250px;
  }

  .about_description {
    padding: 0 2rem;
    margin-bottom: 2rem;
    text-align: justify;
  }
}

/* Medium devices */

@media screen and (max-width: 768px) {
  .about_container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about_header {
    text-align: center;
    padding-right: 0;
  }

  .about_info {
    border: none;
    padding-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 576px) {
  .about_container {
    grid-template-columns: 1fr;
  }

  .about_description {
    padding: 1rem;
  }
}

/* Small devices */
@media screen and (max-width: 350px) {
  .about_info {
    grid-template-columns: repeat(2, 1fr);
  }
}
